#topBanner {
    background-color: black;
    color: white;
}

#dropdownMenu {
    --bs-nav-link-color: white;

}

h2 {
    text-wrap: none;
    font-family: Serif, serif;
}

.linkThing {
    font-family: Figtree, sans-serif;

}

#vl {
    border-right: 2px solid white;
    margin-top: 10px;
    height: 14px;
    width: 2px;
    padding-left: 0;
}

.hl {
    border-top: 4px solid white;
    opacity: 1;
}

.topLinks {
    height: 14px;
    width: 14px;
    padding: 0 15px;
    margin-bottom: 5px;
    color: white;
    --bs-nav-link-color: white;
}

.navTabs {
    font-size: 12px;
    text-transform: uppercase;
    color: black;
    font-family: Figtree, sans-serif;
    font-weight: 600;
    letter-spacing: 1.2px;
    --bs-navbar-nav-link-padding-x : 1.25rem;
}

.bottomNav {
}

.diningHeader {
    padding-top: 50px;
    font-family: Figtree, sans-serif;
    font-size: 32px;
    font-weight: 300;
}

.diningText {
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0px;
    font-family: Figtree, sans-serif;
    line-height: 1.444;
    text-transform: none;
}

.marginSide {
    max-width: 1170px;
}

#cardBackground {
    background: url('https://www.alamoanacenter.com/content/dam/b2c/custom-campaigns/ala-moana-center/backgrounds/AMC_298997518-repeating_2000x400_lt.jpg');
}

.card {
    min-height: 266px;
    min-width: 281px;
    padding: 40px 20px 20px;
    position: relative;
    margin: 0.5rem 0 1rem 0;
    background-color: #fff;
    transition: box-shadow .25s;
    border-radius: 0px;
}

.cardImage {
    height: 121px;
    object-fit: scale-down;
}

.smallMarginSide {
    max-width: 970px;
}

#pixlee_widget_iframeb9bea792-557c-2192-c4c7-5ba7972952a7 {
    height: 388px; border: none; overflow: hidden; width: 741px;
}

#powered_by_pixleed8179957-c476-b9f3-0769-81d758f4b254 {
    opacity: 1 !important; visibility: visible !important; height: 8px !important; width: 200px !important; display: flex !important; color: rgb(102, 114, 130) !important; text-transform: uppercase !important; align-items: center !important; justify-content: center !important; font-size: 10px !important; font-weight: normal !important; font-family: sofia_regular, sans-serif !important; text-decoration: none !important; padding: 5px !important; margin: 0px auto !important; line-height: 0 !important; position: relative !important; inset: auto !important; transform: none !important; transition: none 0s ease 0s !important; animation: auto ease 0s 1 normal none running  !important; filter: none !important; backface-visibility: visible !important; box-shadow: none !important; z-index: 100 !important; border: none !important;
}

#pixlee-logo {
    margin-right: 3px!important;width: 8px !important;height: 8px !important;
}